import {useContext} from "react";
import {useForm, Controller} from "react-hook-form";
import {useDispatch} from "react-redux";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {InputField} from "~/components/Forms";
import {authActions} from "~/features/Auth/authSlice";
import {AppContext} from "~/context/AppProvider";
import {apiError, handleRequest} from "~/utils";
import {authApi} from "~/api";
import {globalNavigate} from "~/routes/GlobalHistory";

function AuthLoginForm() {

	const dispatch = useDispatch();

	const {setUserLogin} = useContext(AppContext);

	const initialValues = { username: '', password: '' }

	const validationSchema = Yup.object().shape({
		username: Yup.string().required('Tên đăng nhập không được để trống').min(5, 'Tên đăng nhập quá ngắn'),
		password: Yup.string().required('Mật khẩu không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors } } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const onSubmit = async (data) => {

		let [error, response] = await handleRequest(authApi.token(data.username, data.password));

		let message = apiError(`Đăng nhập thất bại`, error, response);

		if(!message) {
			const accessToken = {
				accessToken   : response.data.accessToken,
				expires       : response.data.expires,
			}
			localStorage.setItem('access_token', JSON.stringify(accessToken));
			localStorage.setItem('reload_token', response.data.refreshToken);
			dispatch(authActions.loginSuccess(response.data))
			setUserLogin(true);
			globalNavigate("/");
		}
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<div>
				<Controller control={control} name="username"
					render={({ field }) => (
						<InputField label="Tên đăng nhập" placeholder="Tên đăng nhập" errors={errors} {...field}/>
					)}
				/>
				<Controller control={control} name="password"
					render={({ field }) => (
						<InputField label="Mật khẩu đăng nhập" type="password" placeholder="Mật khẩu đăng nhập" errors={errors} {...field}/>
					)}
				/>
				<div className="form-group d-flex justify-content-end">
					<Button primary type="submit">Đăng nhập</Button>
				</div>
			</div>
		</form>
	)
}

export default AuthLoginForm;