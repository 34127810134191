import {Fragment, useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {Col, Row} from "antd";
import {
	Button,
	Loading,
	StatusLabel,
	Comments
} from "~/components";
import {
	GroupRadioField,
	TextAreaField
} from "~/components/Forms";
import {LIST_FORM_STATUS} from "~/app/constants";
import {
	renderDate,
} from "~/utils";
import {
	useCan
} from "~/hooks";

function FormSabbaticalInfo({item, onHandleSubmit}) {

	const canStatusAssign = useCan('formSabbaticalStatusAssign');

	const canStatusManager = useCan('formSabbaticalStatusManager');

	const statusOptions = [];

	for (const [key, value] of Object.entries(LIST_FORM_STATUS)) {
		statusOptions.push({value : key, label : <StatusLabel small type={value.color}>{value.label}</StatusLabel>})
	}

	let initialValues = {}

	if(canStatusManager) initialValues = {...initialValues, statusManager : '', noteManager : ''}
	if(canStatusAssign) initialValues = {...initialValues, statusAssign : '', noteAssign : ''}

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues
	});

	useEffect(() => {
		if(item?.id) {
			if(canStatusManager) initialValues.statusManager  = item.statusManager;
			if(canStatusAssign) initialValues.statusAssign   = item.statusAssign;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<Row className="mb-2" gutter={10}>
			<Col className="gutter-row" span={12}>
				<form className="form" onSubmit={handleSubmit(onSubmit)}>
					{isSubmitting && <Loading/>}
					<p className="heading">Thông tin xin nghỉ phép</p>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Người làm đơn:</Col>
						<Col className="gutter-row" span={18}>
							{`${item.user.firstname} ${item.user.lastname}`}
						</Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Nghỉ từ:</Col>
						<Col className="gutter-row" span={18}>
							{`${renderDate(item.startDay)} ${item.timeStart}`}
						</Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Đến ngày:</Col>
						<Col className="gutter-row" span={18}>
							{`${renderDate(item.endDay)} ${item.timeEnd}`}
						</Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Lý do:</Col>
						<Col className="gutter-row" span={18}>{item.note}</Col>
					</Row>
					<p className="heading">Các cấp</p>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Quản lý:</Col>
						<Col className="gutter-row" span={18}><StatusLabel small type={LIST_FORM_STATUS[item.statusManager].color}>{LIST_FORM_STATUS[item.statusManager].label}</StatusLabel></Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Nhân sự:</Col>
						<Col className="gutter-row" span={18}><StatusLabel small type={LIST_FORM_STATUS[item.statusAssign].color}>{LIST_FORM_STATUS[item.statusAssign].label}</StatusLabel></Col>
					</Row>
					{
						canStatusAssign &&
						<Fragment>
							<p className="heading">Duyệt thông tin (Nhân sự)</p>
							<Controller control={control} name="statusAssign" render={({field}) => (
								<GroupRadioField options={statusOptions} errors={errors} {...field}/>
							)}/>
							<Controller control={control} name="noteAssign" render={({ field }) => (
								<TextAreaField label="Lý kiến" rows={5} errors={errors} {...field} />
							)}/>
						</Fragment>
					}
					{
						canStatusManager &&
						<Fragment>
							<p className="heading">Duyệt thông tin (Quản lý)</p>
							<Controller control={control} name="statusManager" render={({field}) => (
								<GroupRadioField options={statusOptions} errors={errors} {...field}/>
							)}/>
							<Controller control={control} name="noteManager" render={({ field }) => (
								<TextAreaField label="Lý kiến" rows={5} errors={errors} {...field} />
							)}/>
						</Fragment>
					}
					{
						(canStatusAssign || canStatusManager) &&
						<div className="form-group d-flex justify-content-end modal-bottom">
							<hr />
							<Button primary type="submit">Lưu</Button>
						</div>
					}
				</form>
			</Col>
			<Col className="gutter-row" span={12}><Comments type="form-sabbatical" objectId={item.id} /></Col>
		</Row>
	)
}

export default FormSabbaticalInfo;